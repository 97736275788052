<template>
  <!-- 分段式进度条 -->
  <div>
    <div class="father">
      <div
        class="children"
        v-for="(item, index) in data.data"
        :key="index"
        :style="{
          width: `${selfWidth(item.use)}px`,
          backgroundColor: bgc(),
          borderRadius:
            index == 0 ? '10px 0 0 10px' : index + 1 == data.data.length ? '0 10px 10px 0' : '0',
        }"
      ></div>
    </div>
    <div class="name">
      <div
        v-for="item in data.data"
        :key="item.name"
        :style="{
          width: selfWidth(item.use) + 'px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }"
      >
        <!-- <span :style="{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: bgc(), }"></span> -->
        <el-tooltip
          class="item"
          effect="dark"
          :content="item.name + item.use + 'GB'"
          placement="bottom"
        >
          <p style="margin: 10px 0 0">
            <span style="margin-right: 10px">{{ item.name }}</span>
            <span>{{ item.use }}GB</span>
          </p>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MySelfProg',
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      // 总长度
      allWidth: 0,
    };
  },
  mounted() {
    this.width();
  },
  methods: {
    // 随机颜色
    bgc() {
      return `rgb(${Math.round(Math.random() * 255)},${Math.round(
        Math.random() * 255,
      )},${Math.round(Math.random() * 255)})`;
    },
    // 获取总长度
    width() {
      this.allWidth = document.querySelector('.father').offsetWidth;
    },
    // 单个长度
    selfWidth(e) {
      return (this.allWidth / this.data.all) * e;
    },
  },
};
</script>

<style lang="scss" scoped>
.father {
  width: 100%;
  height: 10px;
  background-color: #f2f2f2;
  display: flex;
  border-radius: 10px;

  .children {
    width: 100%;
    height: 10px;
    background-color: red;
  }
}

.name {
  border: 1px solid white;
  display: flex;
  font-size: 12px;

  .item {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
