<template>
  <div>
    <div v-if="isShow == 0" class="body">
      <div class="bodyHeader">
        <el-card shadow="hover" class="componentsList" v-for="(item, i) in cloudListData" :key="i">
          <div>
            <div class="memoryBox">
              <div
                :style="{
                  width: '100%',
                  height: '80px',
                  backgroundColor: ' #ECECEC',
                  backgroundImage: bgc(i),
                }"
                @click="skip(item)"
              ></div>
              <div class="btnBox" v-if="item.type != 1">
                <span class="btn" @click="editSpace(item)">编辑</span>
                <!-- <span class="btn" v-if="item.type != 2" @click="deleteSpace(item)">删除</span> -->
              </div>
              <h4 class="nameTitle">
                {{ item.name }}
              </h4>
            </div>
            <div style="padding: 10px 10px; height: 35px" @click="skip(item)">
              <el-progress
                :percentage="item.usage ? item.usage : 0"
                :color="customColorMethod"
                :show-text="false"
              ></el-progress>
              <p v-if="item.type == 1" style="font-size: 12px">不占用企业空间</p>
              <p v-if="item.type != 1" style="float: right; font-size: 12px">
                <span style="margin: 0 10px; padding: 0 10px">{{ item.usage }}%</span>
                <span>{{ item.usedsize_GB }}GB/{{ item.capacity_GB }}GB</span>
              </p>
            </div>
          </div>
        </el-card>
        <el-card shadow="hover" class="componentsListAdd" v-if="cloudListDataRole == 1">
          <div class="addBtn" @click="openAddStoreroom">
            <div>
              <i class="el-icon-plus"></i>
              <p>分配新空间</p>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div v-else class="body">
      <el-card
        style="width: 100%; box-sizing: border-box; margin: 0 -5px"
        v-loading="isOpen"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="twoPage">
          <h4 style="margin-top: 0; display: inline-block">
            <i
              class="el-icon-back"
              style="font-size: 18px; font-weight: bold; cursor: pointer"
              @click="isShow = 0"
            ></i>
            {{ progressData.title }}
            <i
              style="font-size: 18px; font-weight: bold; cursor: pointer"
              class="el-icon-refresh-right"
              @click="titleData"
            ></i>
          </h4>
          <p style="font-size: 12px">
            <span>{{ progressData.use }}GB</span>
            /
            <span>{{ progressData.all }}GB</span>
          </p>
          <MySelfProg :data="progressData" />
        </div>
      </el-card>
      <div class="oneBtn">
        <span :class="btnOne == 0 ? 'btn1' : 'btn'" @click="openData(0)">数字构件</span>
        <!-- <span :class="btnOne == 1 ? 'btn1' : 'btn'" @click="btnOne = 1">方案管理</span> -->
        <span :class="btnOne == 2 ? 'btn1' : 'btn'" @click="openData(2)">案例管理</span>
        <span :class="btnOne == 3 ? 'btn1' : 'btn'" @click="openData(3)">渲染图片</span>
      </div>
      <!-- 数字构件 -->
      <el-card v-if="btnOne == 0" style="width: 100%; box-sizing: border-box; margin: 0 -5px">
        <div style="display: flex; flex-direction: column">
          <!-- <div style="overflow: hidden;">
                        <div class="headerList" :style="{ height: `${(level) * 45 + 30}px` }">
                            <ArrList :data='dataList' @chartletList='chartletList' />
                        </div>
                    </div> -->
          <div style="text-align: left; margin: 20px">
            <category :categoryData="dataList" :events="events"></category>
          </div>
          <!-- <div style="text-align: right;margin: 0 10px;font-size: 13px;">
                        <p style="margin: 0;padding: 0;">已上传299个构件 占用201.27GB容量</p>
                    </div> -->
          <div>
            <el-card class="box-card" shadow="hover" v-for="(item, i) in componentList" :key="i">
              <div>
                <div style="width: 200px; height: 200px; background-color: #f0f0f0">
                  <img
                    style="width: 100%; height: 100%; object-fit: cover"
                    :src="item.modelIcon"
                    alt=""
                  />
                </div>
                <div>
                  <p class="cardname">
                    {{ item.modelName }}
                  </p>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div style="text-align: center; margin: 25px 0">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            :page-sizes="[10, 15, 30, 35]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.totalItems"
          ></el-pagination>
        </div>
      </el-card>
      <!-- 方案管理 
            <div v-if="btnOne == 1" style="text-align: right;font-size: 13px;margin:0 10px 10px;">已上传299个方案 占用201.27GB容量
            </div>
            <el-card v-if="btnOne == 1" style="width: 100%;box-sizing: border-box;margin:0 -5px;padding: 15px;">
                <el-card shadow="hover" v-for="i in 10" :key="i">
                    <div>
                        <div style="width: 242px;height: 200px;background-color: #000000;"></div>
                        <div
                            style="width: 242px;height: 100px;background-color: #F2F2F2;padding: 15px;box-sizing: border-box;">
                            <h4 class="schemeName">客厅背景墙设计</h4>
                            <div style="display: flex;align-items: center;">
                                <img style="width: 35px;height: 35px;border-radius: 40px;margin-right: 10px;" src=" "
                                    alt="">
                                <span style="font-size: 14px;">张宗侯</span>
                            </div>
                        </div>
                    </div>
                </el-card>
                <div style="text-align: center; margin : 25px 0">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.totalItems">
                    </el-pagination>
                </div>
            </el-card>-->
      <!-- 案例管理 -->
      <!-- <div v-if="btnOne == 2" style="text-align: right;font-size: 13px;margin:0 10px 10px;">
                已上传299个方案&emsp;占用201.27GB容量
            </div> -->
      <el-card
        v-if="btnOne == 2"
        style="width: 100%; box-sizing: border-box; margin: 0 -5px; padding: 15px"
      >
        <el-card shadow="hover" v-for="(item, i) in caseDataList" :key="i">
          <div>
            <div style="width: 242px; height: 200px; background-color: #000000"></div>
            <div
              style="
                width: 242px;
                height: 100px;
                background-color: #f2f2f2;
                padding: 15px;
                box-sizing: border-box;
              "
            >
              <h4 class="schemeName">{{ item.title }}</h4>
              <div style="display: flex; align-items: center">
                <img
                  style="
                    width: 35px;
                    height: 35px;
                    border-radius: 40px;
                    margin-right: 10px;
                    object-fit: cover;
                  "
                  :src="item.avatar"
                  alt=""
                />
                <span style="font-size: 14px">{{ item.nickname }}</span>
              </div>
            </div>
          </div>
        </el-card>
        <div style="text-align: center; margin: 25px 0">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            :page-sizes="[10, 15, 30, 35]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.totalItems"
          ></el-pagination>
        </div>
      </el-card>
      <!-- 渲染图片 -->
      <div v-if="btnOne == 3" style="text-align: right; font-size: 13px; margin: 0 10px 10px">
        <!-- 共{{ page.totalItems }}张 -->
        <!-- &emsp;占用{{ number }}容量 -->
      </div>
      <el-card
        v-if="btnOne == 3"
        style="width: 100%; box-sizing: border-box; margin: 0 -5px; padding: 15px"
      >
        <el-card shadow="hover" v-for="(item, i) in renderImg" :key="i">
          <div @click="openImg(item)">
            <div style="width: 295px; height: 160px; position: relative">
              <div class="imgAll" v-if="item.ImageTypes == 1">全景图</div>
              <!-- <span class="imgNum">{{ carouselIndex }}/12</span> -->
              <img
                style="width: 100%; object-fit: cover; height: 100%"
                :src="item.path_narrow"
                alt=""
              />
            </div>
            <h4 class="imgName">{{ item.image_name ? item.image_name : '展示图' }}</h4>
          </div>
        </el-card>
        <div style="text-align: center; margin: 25px 0">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            :page-sizes="[10, 15, 30, 35]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.totalItems"
          ></el-pagination>
        </div>
      </el-card>
    </div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="300px">
      <div>
        <el-form label-position="right" :model="formLabelAlign">
          <el-form-item label="空间名称">
            <el-input
              v-model="formLabelAlign.name"
              maxlength="10"
              show-word-limit
              placeholder="请输入空间名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="空间容量">
            <el-input
              class="oneInput"
              v-model="formLabelAlign.capacity"
              placeholder="最小分配容量为1"
            >
              <span style="color: black" slot="suffix">GB</span>
            </el-input>
          </el-form-item>
          <el-form-item label="空间使用部门" v-show="typeTwo">
            <el-select
              style="width: 100%"
              v-model="formLabelAlign.department"
              placeholder="请选择使用部门"
              clearable
            >
              <el-option
                v-for="item in getRole"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">返回</el-button>
        <el-button
          style="background-color: #29303e; border: 1px solid #29303e"
          type="primary"
          @click="addStoreroom"
        >
          保存
        </el-button>
      </span>
    </el-dialog>
    <!-- 渲染图片查看页 -->
    <el-dialog title="图片详情" :visible.sync="outerVisible" :before-close="handleClose">
      <div class="img" style="width: 100%; height: 500px">
        <img
          v-if="imgUrl != ''"
          style="object-fit: cover; width: 100%; height: 100%"
          :src="imgUrl"
        />
        <div v-if="sceneUrl != ''" style="width: 100%; height: 100%">
          <three-view :sceneUrl="sceneUrl" :autoRotate="false" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { modelsConfig_list } from '@/common/modelList';
import MySelfProg from '../../components/progress/progress.vue';
// import ArrList from '../../components/arrList.vue';
// import Bus from '@/common/bus.js';
import ThreeView from '../../components/three/three-view.vue';
import {
  cloudspaceSetsave,
  cloudspaceGetlist,
  cloudspaceGetinfo,
  cloudspaceSetdel,
  digital_componentIndex,
  album_list,
  casemanageCase_list,
  getusedsizeinfo,
} from '../../common/memory';
import { userGet_role } from '../../common/groupList';
import Category from '../../components/category.vue';
export default {
  components: {
    MySelfProg,
    // ArrList,
    ThreeView,
    Category,
  },
  name: 'memory',
  data() {
    let that = this;
    return {
      events: {
        selectAllEvent(data) {
          const { id } = data;
          that.chartletList(id);
        },
        selectSingleEvent(data) {
          const { id } = data;
          that.chartletList(id);
        },
      },
      // 渐变色
      listBackGround: [
        'linear-gradient(to right, #4facfe 0%, #00f2fe 100%)',
        'linear-gradient(to right, #fa709a 0%, #fee140 100%)',
        'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
        'linear-gradient(-20deg, #00cdac 0%, #8ddad5 100%)',
        'linear-gradient(to right, #f83600 0%, #f9d423 100%)',
      ],
      // loading
      isOpen: true,
      // 新增编辑名字
      title: '分配新空间',
      // 3D图
      sceneUrl: '',
      // 平面图
      imgUrl: '',
      outerVisible: false,
      // innerVisible: false,
      // 轮播图索引
      carouselIndex: 1,
      // 层级
      level: '',
      // 体系数据
      dataList: [],
      // 默认显示数字构件
      btnOne: 0,
      // 默认显示库
      isShow: 0,
      // percentage: 20,
      progressData: {
        data: [
          {
            name: '数字构件',
            use: 0,
          },
          {
            name: '方案管理',
            use: 0,
          },
          {
            name: '案例管理',
            use: 0,
          },
          {
            name: '渲染图片',
            use: 0,
          },
        ],
        all: 0,
        use: 0,
        title: '',
      },
      // 分配新空间
      dialogVisible: false,
      formLabelAlign: {
        name: '',
        capacity: '',
        department: '',
        type: 3,
      },
      // 分页
      page: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
      },
      // 分组列表
      getRole: [],
      // 云空间列表
      cloudListData: [],
      // 云空间添加权限
      cloudListDataRole: '',
      // 空间ID
      spaceId: '',
      // 上传公共空间 默认显示
      typeTwo: true,
      // 构件列表
      componentList: [],
      // 图片数据
      renderImg: [],
      // 占用容量
      number: null,
      // 空间跳转数据
      spatialData: {},
      // 案例列表
      caseDataList: [],
    };
  },
  created() {
    this.init();
    // Bus.$on('getTarget', (data, isShow, level) => {
    //     this.chartletList(data.id)
    //     // this.isShow = isShow
    //     this.level = level
    // });
  },
  watch: {
    dialogVisible() {
      if (this.dialogVisible == false) {
        this.spaceId = '';
        this.typeTwo = true;
      }
    },
    isShow() {
      if (this.isShow == 0) {
        this.progressData = {
          data: [
            {
              name: '数字构件',
              use: 0,
            },
            {
              name: '方案管理',
              use: 0,
            },
            {
              name: '案例管理',
              use: 0,
            },
            {
              name: '渲染图片',
              use: 0,
            },
          ],
          all: 0,
          use: 0,
          title: '',
        };
      }
      this.isOpen = true;
    },
  },
  methods: {
    // 随机色
    bgc(i) {
      // i.forEach((el,i) => {
      //     if (i>4) {

      //     }
      // });
      if (i > 4) {
        let a1 = i % 5;
        // i = 0
        return this.listBackGround[a1];
      } else {
        return this.listBackGround[i];
      }
    },
    // 跳到详情页
    skip(item) {
      this.spatialData = item;
      this.isShow = 1;
      this.numComponent();
      this.titleData();
    },
    // 头部数据
    titleData() {
      getusedsizeinfo({ id: this.spatialData.id })
        .then((res) => {
          let { code, result } = res;
          if (code == 200) {
            this.progressData = result;
            this.$nextTick(() => {
              this.isOpen = false;
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.isOpen = false;
        });
    },
    // tab切换
    openData(e) {
      this.btnOne = e;
      this.page.page = 1;
      this.page.pageSize = 10;
      if (e == 0) {
        this.level = 0;
        this.numComponent();
      }
      if (e == 2) {
        this.case_list();
      }
      if (e == 3) {
        this.applyImg();
      }
    },
    // 数字构件
    numComponent() {
      digital_componentIndex({ classify_id: 0, space_id: this.spatialData.id }).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.componentList = result.info;
          this.page.totalItems = result.total;
        }
      });
    },
    // 案例管理
    case_list() {
      let dt = {
        page: this.page.page,
        limit: this.page.pageSize,
        search: '',
        case_style: '',
        status: '',
        space_id: this.spatialData.id,
      };
      casemanageCase_list(dt).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.caseDataList = result.data;
          this.page.totalItems = result.total;
        }
      });
    },
    // 渲染图片
    applyImg() {
      let dt = {
        page: this.page.page,
        lime: this.page.pageSize,
      };
      album_list(dt).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.renderImg = result.list.data;
          this.page.totalItems = result.list.total;
          this.number = result.capacity;
        }
      });
    },
    // 打开预览图
    openImg(data) {
      this.outerVisible = true;
      if (data.ImageTypes == 1) {
        this.sceneUrl = data.path;
        this.imgUrl = '';
      } else {
        this.sceneUrl = '';
        this.imgUrl = data.path;
      }
    },
    // 关闭图片详情
    handleClose() {
      this.outerVisible = false;
      this.sceneUrl = '';
      this.imgUrl = '';
    },
    // openImgData() {
    //     this.innerVisible = true
    // },
    // // 轮播图
    // carousel(newIndex, oldIndex) {
    //     console.log(newIndex, '现在的索引');
    //     console.log(oldIndex, '原来的索引');
    //     this.carouselIndex = newIndex + 1
    // },
    // 分页
    handleSizeChange(size) {
      this.page.pageSize = size;
      if (this.btnOne == 3) {
        this.applyImg();
      }
    },
    handleCurrentChange(page) {
      this.page.page = page;
      if (this.btnOne == 3) {
        this.applyImg();
      }
    },
    // 进度条颜色渐变
    customColorMethod(percentage) {
      if (percentage < 0) {
        return '#30A054';
      } else if (percentage < 30) {
        return '#094D9B';
      } else if (percentage < 60) {
        return '#F6DF00';
      } else if (percentage < 90) {
        return '#F76300';
      } else {
        return '#F00030';
      }
    },
    // 打开添加新空间页面
    openAddStoreroom() {
      this.title = '分配新空间';
      this.dialogVisible = true;
      this.formLabelAlign = {
        name: '',
        capacity: '',
        department: '',
        type: 3,
      };
    },
    // 分配新空间
    addStoreroom() {
      let dt = {
        id: this.spaceId,
        name: this.formLabelAlign.name,
        capacity: this.formLabelAlign.capacity,
        department: this.formLabelAlign.department,
        type: this.formLabelAlign.type,
      };
      cloudspaceSetsave(dt).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.$message.success(msg);
          this.init();
          this.dialogVisible = false;
        }
      });
    },
    init() {
      modelsConfig_list().then((res) => {
        if (res.code == 200) {
          this.dataList = res.result.config_list;
          this.dataList.splice(0, 0, { name: '所有体系', show: 0, id: 0, level: 1, children: [] });
          this.dataList.forEach((e) => {
            this.addAll(e);
          });
        }
      });
      userGet_role().then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.getRole = result;
        }
      });
      this.cloudList();
    },
    // 云盘列表
    cloudList() {
      cloudspaceGetlist().then((res) => {
        let { code, result } = res;
        if (code == 200) {
          // this.cloudListData = result
          this.cloudListData = result.data;
          this.cloudListDataRole = result.user_role;
        }
      });
    },
    // 在每个数组前添加所有字段
    addAll(e) {
      if (e.children != '') {
        e.children.splice(0, 0, {
          name: '所有分类',
          show: 0,
          id: e.id,
          level: e.level + 1,
          children: [],
        });
        e.children.forEach((el) => {
          this.addAll(el);
        });
      } else {
        return;
      }
    },
    // 切换列表
    chartletList(classData) {
      this.lastClassifyId = classData;
      let dt = {
        is_all: this.lastClassifyId == 0 || this.lastClassifyId == undefined ? 1 : 0,
        classify_id: this.lastClassifyId,
        space_id: this.spatialData.id,
      };
      // classify_id: 100002
      digital_componentIndex(dt).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.componentList = result.info;
          this.page.totalItems = result.total;
        }
      });
    },
    // 编辑空间
    editSpace(e) {
      this.spaceId = e.id;
      this.title = '编辑';
      this.dialogVisible = true;
      if (e.type == 2) {
        this.typeTwo = false;
      }
      // this.formLabelAlign = {
      //     name: '',
      //     capacity: '',
      //     department: '',
      //     type: '',
      // }
      let dt = {
        id: e.id,
      };
      cloudspaceGetinfo(dt).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.formLabelAlign = {
            name: result.name,
            capacity: result.capacity_GB,
            department: result.department,
            type: result.type,
          };
        }
      });
    },
    // 删除空间
    deleteSpace(e) {
      this.$confirm('此操作将永久删除该空间, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          cloudspaceSetdel({ id: e.id }).then((res) => {
            let { code, msg } = res;
            if (code == 200) {
              this.$message({
                type: 'success',
                message: msg,
              });
              this.init();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.nameTitle {
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0;
  padding: 0 10px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 99;
  color: white;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.oneInput {
  ::v-deep :-moz-placeholder {
    text-align: right;
  }

  ::v-deep ::-moz-placeholder {
    text-align: right;
  }

  ::v-deep ::-webkit-input-placeholder {
    text-align: right;
  }

  ::v-deep :-ms-input-placeholder {
    text-align: right;
  }
}

.body {
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;

  .headerList {
    margin: 20px 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 0 20px;
  }

  .memoryBox {
    position: relative;
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);

    // height: 30px;
    .btnBox {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.6);
      color: white;
      font-size: 12px;
      padding: 3px 0;
      opacity: 0;

      .btn {
        cursor: pointer;
      }
    }
  }

  .memoryBox:hover .btnBox {
    opacity: 1;
  }

  .componentsList {
    width: 180px;
    cursor: pointer;
  }

  .componentsListAdd {
    width: 180px;
    border: 1px dashed #bbb;
  }

  ::v-deep .el-card__body {
    padding: 0;
  }

  .addBtn {
    width: 182px;
    height: 135px;
    text-align: center;
    padding-top: 45px;
    box-sizing: border-box;
    font-size: 14px;
  }

  .el-card {
    display: inline-block;
    margin: 10px;
  }

  // .bodyHeader {
  //     .el-card:last-child {
  //         border: 1px dashed #bbb;
  //     }
  // }

  .twoPage {
    padding: 20px;
  }

  .oneBtn {
    margin: 20px 0;

    .btn {
      background-color: #eeeeee;
      font-size: 14px;
      padding: 5px 10px;
      display: inline-block;
      margin-right: 15px;
    }

    .btn1 {
      background-color: #474748;
      color: white;
      font-size: 14px;
      padding: 5px 10px;
      display: inline-block;
      margin-right: 15px;
    }

    .btn:hover {
      background-color: #474748;
      color: white;
    }
  }

  .cardname {
    width: 180px;
    font-size: 14px;
    margin: 20px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .schemeName {
    margin: 0 0 10px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .imgName {
    margin: 20px 10px;
  }

  .imgAll {
    position: absolute;
    top: 10px;
    z-index: 10;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 12px;
    padding: 5px 15px;
    border-radius: 5px;
  }

  .imgNum {
    position: absolute;
    bottom: 10px;
    z-index: 10;
    right: 10px;
    color: white;
    font-size: 13px;
  }
}

.dialog-footer {
  display: block;
  text-align: center;
  margin: 0 auto;

  .el-button {
    width: 125px;
    line-height: 10px;
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #bbb;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;

  .el-form-item {
    margin-bottom: 0;
  }
}

::v-deep .el-carousel__indicators {
  height: 0;
  overflow: hidden;
}

.img {
  ::v-deep .el-card__body {
    padding: 0;
  }
}
</style>
