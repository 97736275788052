import $http from '@/utils/http';

// 分组列表
export function userGet_role(data) {
  return $http.get('/api/user/get_role', data, 'false');
}

// 权限配置所有配置数据
export function getAuthConfigList(data) {
  return $http.post('/api/auth/auth_list', data, 'loadingDiv');
}

// 查询用户
export function userList(data) {
  return $http.post('/api/user/index', data, 'loadingDiv');
}

// 保存分组、权限
export function userSave_group(data) {
  return $http.post('/api/user/save_group', data, 'loadingDiv');
}

// 账号权限
export function authGet_auth(data) {
  return $http.post('/api/auth/get_auth', data, 'loadingDiv');
}

// 账号权限
export function userEdit_group(data) {
  return $http.post('/api/user/edit_group', data, 'loadingDiv');
}

// 列表查看账号权限
export function usergGet_auth(data) {
  return $http.get('/api/user/get_auth', data, 'loadingDiv');
}

// 删除分组
export function usergDelete_group(data) {
  return $http.post('/api/user/delete_group', data, 'loadingDiv');
}

// 分发账号 创建角色
export function distribute_account(data) {
  return $http.post('/api/user/distribute_account', data, 'loadingDiv');
}

// 启用禁用
export function account_forbidden(data) {
  return $http.post('/api/Setting/account_forbidden', data, 'loadingDiv');
}

// 管理员转让
export function administrator_transfer(data) {
  return $http.post('/api/user/administrator_transfer', data, 'loadingDiv');
}

// 修改账号
export function edit_distribute_account(data) {
  return $http.post('/api/user/edit_distribute_account', data, 'loadingDiv');
}
